// regular style toast
@import 'ngx-toastr/toastr';

// menu item font size
.fuse-vertical-navigation-item {
  font-size: 14px !important;
}

// icon height width shadow overriding
.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 22px !important;
  width: 22px !important;
}

// ag-grid
@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
.ag-theme-alpine {
  //   --ag-alpine-active-color: var(--fuse-primary) !important;
  --ag-header-foreground-color: #fff;
  --ag-header-background-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity));
  --ag-alpine-active-color: var(--fuse-primary);
  // --ag-border-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity));
}

.ag-checkbox-input-wrapper.ag-disabled {
  opacity: 1;
}
.theme-default.light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #77e857;
  --mdc-snackbar-supporting-text-color: rgba(0, 0, 0, 0.87);
  --mat-snack-bar-button-color: #000000;
}
.ag-theme-alpine .ag-header {
  border-top-right-radius: 5px;
  border-top-right-radius: 5px;
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 5px;
}

.ag-header-cell-label {
  justify-content: center;
}

.left-aligned .ag-header-cell-label {
  justify-content: flex-start;
}

.ag-header-cell {
  font-size: 15px;
}

.editable-cell:hover {
  border-radius: 5px;
  border-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity));
  cursor: pointer;
}

.ag-row {
  height: 50px;
}

.ag-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-cell-focus {
  border: none !important;
}

.ag-header-cell-comp-wrapper {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.ag-cell-background-red {
  background-color: #f397ab;
}

.ag-cell-disable {
  cursor: not-allowed;
  background-color: #f2f2f2;
}

//custom aggrid theme
.ag-theme-mycustomtheme .ag-header-cell {
  border-right: 1px solid #babfc7;
}
.ag-theme-mycustomtheme .ag-cell {
  border-right: 1px solid #babfc7;
}

// Overriding Card Styles
.mat-mdc-card-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity));
  color: white;
}

// dialog
.mdc-dialog__title {
  padding: 0 !important;
  padding-bottom: 17px !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  background-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
  color: white !important;
  text-align: center;
}

.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px !important;
}

.mdc-dialog__actions {
  justify-content: flex-end !important;
  background: #fbfbfb;
}

// Styles specific to import grading data dialog. Remove vertical scroll.
og-import-grading-data-dialog {
  .mdc-dialog__title {
    min-height: 61px !important;
  }
  .mdc-dialog__content {
    height: calc(80vh - 119px) !important;
    min-height: calc(80vh - 119px) !important;
  }
  .mat-mdc-dialog-actions {
    min-height: 57px !important;
  }
}

// This would be handled when we customize whole theme and would be removed
.custom-btn .mdc-button__label {
  display: flex;
  align-items: center;
}

//show-matches
.notSelected {
  color: red !important;
}

.selected {
  color: green !important;
}

// Select Option group label styling
.mat-mdc-optgroup-label {
  font-weight: bold !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

//custom label
.label {
  font-weight: 500;
  --tw-text-opacity: 1 !important;
  color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
}

// overriding matlabel
.mdc-label {
  margin-bottom: 0px !important;
}

// system settings mappings overriding
.settings-panel form {
  .alt-bg {
    background-color: #fbfbfb;
  }
}

// changed the required icon color (*)
.mdc-text-field:not(.mdc-text-field--disabled) {
  .mdc-floating-label {
    .mat-mdc-form-field-required-marker {
      color: #ff0000;
    }
  }
}

.player-import-grid .ag-header-cell-label {
  font-size: 14px;
  line-height: 1.2;
}

.player-import-grid .ag-header-cell {
  padding: 4px;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (max-width: 1800px) {
    @content;
  }
}
.cdk-overlay-dark-backdrop,
.cdk-overlay-container {
  z-index: 999 !important;
}
@media print {
  body {
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
.grid-cell-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  .play-by-play-print-remove-all {
    display: none;
  }
}
.cdk-global-overlay-wrapper {
  z-index: 999 !important;
}

// We need to hide the subscript so that Number of Sheets input is aligned with the Position Group drop-down
.grade-sheet-count .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  z-index: auto !important;
}

// We need to display required-field-marker where we have mandatory custom dropdowns
.mandatory-marker {
  color: #ff0000;
  &::after {
    margin-left: 1px;
    margin-right: 0px;
    content: '*';
  }
}
